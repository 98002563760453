import '../styles/main.scss';
import SlidersInit from './sliders';
import Menu from './menu';
import BlogAudioInit from './blog-audio';
import WpLeadFormsInit from './wp-lead-forms';
import PlayOnHover from './play-on-hover';

function init() {
  const headerMenu = new Menu('.header__nav', true);
  SlidersInit();
  BlogAudioInit();
  const playOnHoverBlogVideos = new PlayOnHover();
}

window.addEventListener('load', init);


WpLeadFormsInit();
